<template>
    <CModal
      :title="title"
      :color="color"
      size="sm"
      :show.sync="resetModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                ¿Estás seguro que deseas resetear la contraseña del colaborador?<br /><br />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                  disabled
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.job_position.$model"
                  :isValid="checkIfValid('job_position')"
                  placeholder=""
                  autocomplete="given-job_position"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                  disabled
                />
              </CCol>
              <CCol md="6">
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.phone.$model"
                  :isValid="checkIfValid('phone')"
                  placeholder=""
                  autocomplete="given-phone"
                  invalidFeedback="Este campo debe tener un número de Whatsapp de 10 dígitos."
                  disabled
                />
              </CCol>
              <CCol md="12" style="text-align: center;">
                Se notificará al colaborador por medio de Whatsapp, y se le asignará una nueva contraseña.
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Resetear</CButton>
        </template>
        
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import roles from '../../services/roles';

export default {
  name: 'ResetCollaboratorModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      resetModal: false,
      title: "Resetear Contraseña",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Permisos del Acceso'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
      let response = await roles.get(); 

      if(response.type == "success"){
        this.roles = response.data;
      }

      this.rolesOptions = await this.$parseSelectOptionsOrdered(this.roles, "slug", "name");
  },
  methods: {
    showModal (item) {
      this.resetModal = true;
      this.color = "danger";
      this.title = 'Resetear Contraseña';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          job_position: item.job_position,
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("reset", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.resetModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          phone: '',
          job_position: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      job_position: {

      }
    }
  },
}
</script>